import React from 'react';

export const HeroSection = () => {
  return (
    <>
      <div
        id='header'
        className='center-div relative header-hero w-12/12 flex flex-row w-full' 
      >
   
        <div className='bg-left-hero text-white font-primary  d-flex flex-col '>
            
            <span className="uppercase font-size-50">artificial<br/> intelligence</span>
            <span className='font-size-16'>for private capital markets</span>
        </div>
          <img src='/heroGif.gif' alt='konzortia' className='gif-position-header relative' />
          <div className='flex p-0 lg:pr-16 flex-col bg-right-hero mr-2 mb-2 lg:m-0 '>
        <span className='font-primary font-bold text-white font-size-40 '>Alpha Hub
</span>
<span className='text-white font-secondary-italic font-size-30 '>By Konzortia Capital</span>
</div>

      </div>
      {/* <PopupBanner /> */}

    </>
  );
};
