import React from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { ContactCard } from './ContactUs';
import { ContactUs } from '../Home/ContactUs';

export const Description = () => {
 
  return (
    <>
      <div className='px-4 mx-auto container w-12/12 bg-white '>
        <div className='flex flex-col lg:flex-row '>
        <div className='lg:w-9/12 w-12/12 leading-loose pt-12 px-12 flex flex-col content-left justify-left text-left'>
            <span className='font-primary font-size-30 color-dark-blue font-bold py-4'>
              Invest in the High-Growth of Private Markets 
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
            As an angel investor, you need to identify high-potential, early-stage companies that can deliver substantial returns. 
            The private markets are surpassing public markets as the place to generate the highest returns, since companies are now staying private longer.
             However, finding the best deals at the right time can be a challenge. 
            Especially with the lack of data available for private companies.
            </span>
            <div className=" flex flex-col justify-center items-center pt-10" >
              <div className="justify-center items-center text-left" >
                  <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                    Alpha Hub: The Competitive Edge You Need
                  </h2>
                  <span className='font-primary font-size-16 color-dark-blue '>
                  We understand the unique needs of angel investors like you.
                   Investing in private markets means gaining early access to innovative companies before they scale, 
                   and that’s where the most lucrative opportunities lie. But without the right insights, deal sourcing can be time-consuming and risky.
                    Our platform, <b>Alpha Hub</b>, is the solution for your investing problems. 
                  </span>
                  <span className='font-primary font-size-16 color-dark-blue '>
                  <b> Alpha Hub</b> is an AI-powered platform designed to streamline your investment process—so you can discover, evaluate, and execute deals faster,
                   with higher confidence in your decisions. Whether you’re investing individually or as part of a syndicate, 
                   our platform gives you a strategic advantage in today’s competitive private markets.
                   Providing you with investment opportunities you can truly be excited about!  
                  </span>
              </div>
            </div>
            <div className="container flex flex-col justify-center items-center lg:flex-row" >
                    <div className="max-w-full lg:max-w-[1000px] mt-12 lg:mt-20 mb-12">
                        <h2 className="color-dark-blue font-bold text-xl text-left lg:text-center">
                          How We Help You Maximize Returns 
                        </h2>
                        <div className="color-dark-blue mt-8 lg:mt-10 mx-auto pb-8 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 lg:gap-x-[130px] lg:max-w-[1180px]">
                            <div className="flex flex-col items-center lg:items-center px-4 lg:px-2.5 lg:text-center">
                                <img src='/sourcing.png' className='fitwidth' alt='deal sourcing' />  
                                <h3 className="font-bold mt-2 mb-1 text-lg">Swift Effortless Deal Sourcing</h3>
                                <p className="text-center text-sm max-w-[400px]">
                                Our Smart Recommendation Engine does the work for you, using your unique criteria to identify companies that align with your investment strategy. 
                                From industry to stage, geography, you can quickly access a curated list of high-potential opportunities.
                                </p>
                            </div>
                            <div className="flex flex-col items-center lg:items-center px-4 lg:px-2.5 lg:text-center">
                                <img src='/evaluating.png' className='fitwidth' alt='evaluation' />      
                                <h3 className="font-bold mt-2 mb-1 text-lg">Comprehensive Evaluation</h3>
                                <p className="text-center text-sm max-w-[400px]">
                                 Our platform parses through financial documents and market data, delivering automated insights that help you assess company performance and potential growth with speed and accuracy.
                                 Our AI can analyze in minutes what would take weeks for a team of analysts.
                                 </p>
                            </div>
                            <div className="flex flex-col items-center lg:items-center px-4 lg:px-2.5 lg:text-center">
                              <img src='/security.png' className='fitwidth'alt='deal execution'/>
                                <h3 className="font-bold mt-2 mb-1 text-lg">Secure and Seamless Deal Execution</h3>
                                <p className="text-center text-sm max-w-[400px]">
                                Once you’ve found the perfect match, closing the deal is a breeze. From signing contracts to transferring funds, 
                                we provide a secure, decentralized infrastructure that minimizes risk and reduces the complexities of traditional deal-making processes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className=" flex flex-col justify-center items-center pt-10" >
              <div className="justify-center items-center text-center" >
                  <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                    Why Join Alpha Hub? 
                  </h2>
                  <div className="color-white pt-8 lg:pt-16 mt-4 lg:mt-8 mx-auto pb-8 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }} >
                          <h3 className="font-bold font-size-16">Early Access to Game-Changing Startups</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                          Gain access to companies poised for rapid growth in cutting-edge industries.
                           Our real-time database ensures you’re among the first to discover these opportunities.
                          </p>
                      </div>
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }}>
                          <h3 className="font-bold mt-2 mb-1 text-lg">Leverage Advanced AI to Your Advantage</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                           While other investors rely on outdated manual processes,
                           you’ll have AI-powered tools that enhance your deal sourcing and evaluation capabilities, giving you a major edge over the competition. 
                          </p>
                      </div>
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }}>
                          <h3 className="font-bold mt-2 mb-1 text-lg">Support for Angel Syndicates</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                            Investing with others? No problem. Alpha Hub makes it easy for syndicates to collaborate, pool resources, and co-invest in the best opportunities.
                           You’ll be able to manage group deals and stay aligned on key decisions, all within our platform.
                          </p>
                      </div>
                      
                  </div>
              </div>
            </div>
            <div className=" flex flex-col justify-center items-center pt-10" >
                <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                  Join the Future of Private Investing 
                </h2>
                <span className='font-primary font-size-16 color-dark-blue '>
                Whether you&apos;re looking to expand your portfolio or syndicate with other angels for bigger opportunities, 
                <b> Alpha Hub</b> gives you everything you need to invest smarter and faster in high-growth companies. 
                </span>
                <div className='flex flex-col py-2 pt-4'>
                  <div className='flex flex-col lg:flex-row items-center justify-center'>
                      <video controls width="600" height="350">
                          <source src="/founderscircle.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  </div>
                </div>
            </div>
            <div className=" flex flex-col justify-center items-center pt-10" >
              <div className="justify-center items-center text-center" >
                <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                Don’t Miss Out on the Next Big Opportunity: Join Our Founder’s Circle 
                </h2>
                <span className='font-primary font-size-16 color-dark-blue '>
                Angel investors who leverage cutting-edge tools will be the first to tap into private market opportunities with the greatest potential for outsized returns. 
                Don’t get left behind—join our Founder’s Circle and help shape the future of private capital markets. 
                Together, we’ll accelerate innovation, maximize returns, and lead the next wave of private market investing. 
                </span>
                <div className='flex flex-col pt-24'>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' alt='konzortia' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Sourcing and Matching Deals (AI/ML): </b>
                        Leveraging advanced AI and ML algorithms to optimize deal sourcing and
                      matching.
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' alt='konzortia'/>
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Free Access: </b>
                      1 year of free access to the platform after the beta phase ends.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' alt='konzortia' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Premium Support: </b> 
                      Priority assistance and expert troubleshooting throughout the beta testing phase.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' alt='konzortia' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Recognition: </b>
                      Your firm can be featured in Alpha Hub marketing material.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' alt='konzortia' />
                    </div>

                    <span className='font-primary color-dark-blue text-left'>
                      <b>Networking Opportunities: </b> 
                      Connect with other beta testers and join our advisory board or ongoing user group.{' '}
                    </span>
                </div>
                <span className='font-primary font-size-16 color-dark-blue pt-10'>
                  To discuss the program, schedule a meeting with  <a  className="font-primary font-bold" style={{ color: '#2698C6' }} href="https://calendly.com/walter-gomez-kc/founders-circle-meeting?month=2024-10">our founder</a>.  
                  <br/>
                  <b>Let&apos;s build the future of private investing, together</b>.
                </span>  
              </div>
            </div>
          </div>
            
        </div>

          <div className='py-12 w-12/12 lg:w-3/12'>
            <ContactUs />
            <div style={{ paddingTop: '4.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR ONEPAGER
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/onepage.png' alt='konzortia' />
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/65ytphyfnb4ymzdh')
                  }
                >
                  Onepager
                </button>
              </div>
            </div>
            <div style={{ paddingTop: '4.5rem' }}>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/video1.png' alt='konzortia' />
              </div>
            </div>
            <ContactCard />
            <div className='flex  py-10 px-2 w-12/12 ' style={{ paddingTop: '4.5rem' }}>
              <div className='social-media-card w-100 h-100'>
                <div className='flex flex-row w-12/12 h-100 center-div'>
                  <span className='font-secondary-italic text-white font-size-24'>
                    Follow us!
                  </span>
                  <div className='px-2 color-white'>
                    <a href="https://x.com/konzortia_c"><AiOutlineTwitter size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.facebook.com/konzortia"><AiFillFacebook size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.linkedin.com/company/konzortiacapital/"><AiFillLinkedin size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.instagram.com/konzortia_capital/"><AiOutlineInstagram size={20} /></a>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: '4.5rem', backgroundColor: '#F4F4F4' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR PITCH DECK
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/investhubpc.png' alt='Alpha Hub'/>
              </div>
              <div style={{ paddingTop: '1.5rem' }}>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/n5h9qf9rbjx9epsh')
                  }
                >
                  Pitch Deck
                </button>
              </div>
            </div>

            

            <div style={{ paddingTop: '4.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  Take Action
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img
                  src='/video2.png' alt='konzortia'
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/ue2azbg9r4g93d65')
                  }
                />
              </div>
              <div>
                <span className='font-primary color-dark-blue font-size-16 py-4'>
                  <b>Walter Gomez,</b> CEO & Founder
                </span>
              </div>
              <div>
                <button
                  className='btn-blue font-primary font-bold color-white font-size-12'
                  style={{ width: '300px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://calendly.com/walter-gomez-kc/priority-investor-zoom-meeting-with-the-found-clone-1?month=2024-04')
                  }
                >
                  Schedule a Call with our CEO today
                </button>
              </div>
            </div>

            <div style={{ paddingTop: '7.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary  font-size-22 color-dark-blue'>
                  <i>Request access</i>
                  <br />
                  TO OUR DATA ROOM
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/dataroom.png' alt='konzortia data room'/>
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-12'
                  style={{ width: '174px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://dealroom.konzortiacapital.com/')
                  }
                >
                  Data Room
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};
