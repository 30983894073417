export const ABOUT = [
    { name: "99 Wall Street Suite 5895, New York, NY 10005" },
    { name: "Privacy policy", link: "https://konzortiacapital.com/privacy-policy/" },
  ];
  export const COMPANY = [
    { name: "About", link: "about" },
    { name: "Careers", link: "careers" },
  ];
  export const PLATFORM = [
    { name: "Alpha Hub", link: "https://alpha-hub.ai/" },
  ];
  export const CONTACT = [
    { name: "capital@konzortia.capital"},
    { name: "+1 (833) 930-2161" },
  ];
  
  export const Icons = [
    { name: "logo-facebook", link: "https://www.facebook.com/konzortia/" },
    { name: "logo-twitter", link: "https://twitter.com/konzortia_c" },
    { name: "logo-linkedin", link: "https://www.linkedin.com/company/konzortiacapital/" },
    { name: "logo-instagram", link: "https://www.instagram.com/konzortia_capital/" },
  ];